/* You can add global styles to this file, and also import other style files */
body {
    transition-property: padding-right;
    transition-duration: 0.4s;
    padding-right: 0;
    margin: 0;
}

body.has-roster {
    padding-right: 14em;
}

.form-control {
    width: 100%;
    padding: 0.5em;
    margin: 0.5em 0;
}

.form-group {
    padding: 0.5em 0;
}

.ng-valid[required], .ng-valid.required  {
    border-left: 5px solid #42A948; /* green */
}

.ng-invalid:not(form)  {
    border-left: 5px solid #a94442; /* red */
}

.container {
    margin: 0 auto;
    max-width: 700px;
}

.container, .container button, .container .form-control {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
}

button {
    padding: 0.5em;
    border: 1px solid #5385c3;
    background-color: #5385c3;
    color: #fff;
    margin: 0.5em 0.5em 0 0;
    cursor: pointer;
}
